import { SSOInfo, UnifiedAuthState } from "./context";

type Action =
  | { type: "NAVIGATE_STARTED" }
  | { type: "NAVIGATE_COMPLETE" }
  | { type: "LOGIN_WITH_IDENTIFIER_AND_PASSWORD_STARTED" }
  | { type: "LOGIN_WITH_IDENTIFIER_AND_PASSWORD_COMPLETE" }
  | { type: "HANDLE_REDIRECT_CALLBACK_STARTED" }
  | { type: "HANDLE_REDIRECT_CALLBACK_COMPLETE" }
  | { type: "SSO_LOGIN_STARTED" }
  | { type: "SSO_LOGIN_COMPLETE"; ssoLoginInfo?: SSOInfo }
  | { type: "LOGOUT" }
  | { type: "ERROR"; error: Error };

export const reducer = (state: UnifiedAuthState, action: Action): UnifiedAuthState => {
  switch (action.type) {
    case "NAVIGATE_STARTED":
    case "SSO_LOGIN_STARTED":
    case "LOGIN_WITH_IDENTIFIER_AND_PASSWORD_STARTED":
    case "HANDLE_REDIRECT_CALLBACK_STARTED":
      return {
        ...state,
        isLoadingWithSendGrid: true,
      };
    case "LOGOUT":
    case "NAVIGATE_COMPLETE":
    case "LOGIN_WITH_IDENTIFIER_AND_PASSWORD_COMPLETE":
    case "HANDLE_REDIRECT_CALLBACK_COMPLETE":
      return {
        ...state,
        isLoadingWithSendGrid: false,
        error: undefined,
      };
    case "SSO_LOGIN_COMPLETE":
      return {
        ...state,
        ssoLoginInfo: action.ssoLoginInfo,
        isSSOLogin: !!action.ssoLoginInfo,
        isLoadingWithSendGrid: false,
        error: undefined,
      };
    case "ERROR":
      return {
        ...state,
        isLoadingWithSendGrid: false,
        error: action.error,
      };
  }
};
