import { Box, Text } from "@twilio-paste/core";
import { LogoTwilioIcon } from "@twilio-paste/icons/esm/LogoTwilioIcon";
import { ProductSendGridIcon } from "@twilio-paste/icons/esm/ProductSendGridIcon";

export type LogoAvatarProps = {
  logo: "twilio" | "sendgrid";
};

export const LogoAvatar = ({ logo }: LogoAvatarProps) => {
  const isTwilioLogo = logo === "twilio";

  return (
    <Box
      display={"flex"}
      boxShadow={isTwilioLogo ? "shadowBorderDecorative40Weaker" : "shadowBorderWeaker"}
      borderStyle={"solid"}
      borderRadius={"borderRadius30"}
      borderColor={isTwilioLogo ? "colorBorderDecorative40Weaker" : "colorBorderWeaker"}
      padding={"space40"}
      data-testid={isTwilioLogo ? "twilio-logo" : "sendgrid-logo"}>
      {isTwilioLogo ? (
        <LogoTwilioIcon decorative size={"sizeIcon90"} color={"colorTextBrandHighlight"} />
      ) : (
        <Text as="span" style={{ color: "#51A9E3" }}>
          <ProductSendGridIcon decorative size={"sizeIcon90"} />
        </Text>
      )}
    </Box>
  );
};
