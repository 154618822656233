import { useCallback, useMemo } from "react";
import { useCookies } from "react-cookie";
import { cookieSetOptions } from "../cookieSetOptions";
import { SETUP_2FA_REQUIRED, SETUP_2FA_REQUIRED_CHECKPOINT } from "./constants";

export enum Setup2FARequiredCheckpoint {
  EMAIL = "email",
  SETUP_2FA = "setup 2fa",
  NONE = "none",
}

export const useSetup2FARequired = () => {
  const [cookies, setCookie, removeCookie] = useCookies([SETUP_2FA_REQUIRED]);
  const value = useMemo(() => {
    return cookies.setup_2fa_required;
  }, [cookies]);
  const setSetup2FARequired = useCallback(
    (value: boolean) => {
      setCookie(SETUP_2FA_REQUIRED, value);
    },
    [setCookie]
  );
  const removeSetup2FARequired = useCallback(() => {
    removeCookie(SETUP_2FA_REQUIRED, cookieSetOptions());
  }, [removeCookie]);
  return useMemo(
    () => [value, setSetup2FARequired, removeSetup2FARequired],
    [value, setSetup2FARequired, removeSetup2FARequired]
  );
};

export const useSetup2FARequiredCheckpoint = () => {
  const [cookies, setCookie, removeCookie] = useCookies([SETUP_2FA_REQUIRED_CHECKPOINT]);
  const value = useMemo(() => {
    return cookies.setup_2fa_required_checkpoint;
  }, [cookies]);
  const setSetup2FARequiredCheckpoint = useCallback(
    (value: Setup2FARequiredCheckpoint) => {
      setCookie(SETUP_2FA_REQUIRED_CHECKPOINT, value);
    },
    [setCookie]
  );
  const removeSetup2FARequiredCheckpoint = useCallback(() => {
    removeCookie(SETUP_2FA_REQUIRED_CHECKPOINT, cookieSetOptions());
  }, [removeCookie]);
  return useMemo(
    () => [value, setSetup2FARequiredCheckpoint, removeSetup2FARequiredCheckpoint],
    [value, setSetup2FARequiredCheckpoint, removeSetup2FARequiredCheckpoint]
  );
};
