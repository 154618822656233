import { useLayoutEffect, useRef } from "react";
import { websiteContext } from "../../context";
import { useExternalNavigate } from "../../external-navigate";
import { Loading } from "../../Loading";
import { LOGOUT_REDIRECT_TO } from "../../unified-auth";

export default function LoggedOut() {
  const websiteNavigate = useExternalNavigate(websiteContext);
  const didNavigate = useRef(false);

  useLayoutEffect(() => {
    if (didNavigate.current) {
      return;
    }
    didNavigate.current = true;
    websiteNavigate("/", { replace: true, redirect: LOGOUT_REDIRECT_TO });
  }, [websiteNavigate]);

  return <Loading />;
}
