import {
  Button,
  ProductSwitcher,
  ProductSwitcherButton,
  ProductSwitcherItem,
  Topbar,
  TopbarActions,
  useProductSwitcherState,
  UserDialog,
  UserDialogContainer,
  UserDialogList,
  UserDialogListItem,
  UserDialogSeparator,
  UserDialogUserEmail,
  UserDialogUserInfo,
  UserDialogUserName,
  useUserDialogListState,
} from "@twilio-paste/core";
import { DocumentationIcon } from "@twilio-paste/icons/esm/DocumentationIcon";
import { LogoTwilioIcon } from "@twilio-paste/icons/esm/LogoTwilioIcon";
import { LogOutIcon } from "@twilio-paste/icons/esm/LogOutIcon";
import { ProductEmailAPIIcon } from "@twilio-paste/icons/esm/ProductEmailAPIIcon";
import { ProductSegmentIcon } from "@twilio-paste/icons/esm/ProductSegmentIcon";
import { SupportIcon } from "@twilio-paste/icons/esm/SupportIcon";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";

import { LOGO_ALT, TWILIO_SENDGRID_LOGO } from "./constants";
import { useExternalNavigate } from "./hooks/useExternalNavigate";

export type NavbarProps = {
  showProductSwitcher: boolean;
  showUserMenu: boolean;
  name: string;
  email: string;
};

const env = process.env;
const twilioBaseUrl = env.REACT_APP_TWILIO_BASE_URL || "https://www.twilio.com";
const segmentBaseUrl = env.REACT_APP_SEGMENT_BASE_URL || "https://segment.com";

export const Navbar = ({ showProductSwitcher, showUserMenu, name, email }: NavbarProps) => {
  const userDialogList = useUserDialogListState();
  const productSwitcher = useProductSwitcherState();
  const externalNavigation = useExternalNavigate();

  const encodedEmail = encodeURIComponent(email);
  const twilioConsoleUrl = `${twilioBaseUrl}/unified_login/start?email=${encodedEmail}&context=switch&source=sendgrid`;
  const userSettingsUrl = `${twilioConsoleUrl}&deeplink=user-settings`;
  const segmentUrl = `${segmentBaseUrl}/unified_login/start?email=${encodedEmail}&context=switch&source=sendgrid`;
  const supportUrl = "https://support.sendgrid.com/hc/en-us";

  return (
    <Topbar id="topbar">
      <TopbarActions justify="start">
        <div />
      </TopbarActions>
      <TopbarActions justify="center">
        <img alt={LOGO_ALT} src={TWILIO_SENDGRID_LOGO} height={40} width={137} />
      </TopbarActions>
      <TopbarActions justify="end">
        {showProductSwitcher && (
          <>
            <ProductSwitcherButton {...productSwitcher} i18nButtonLabel="Switch products" />
            <ProductSwitcher {...productSwitcher} aria-label="Available products">
              <ProductSwitcherItem
                {...productSwitcher}
                name="product"
                value="twilio"
                checked={false}
                onChange={() => {
                  window.location.href = twilioConsoleUrl;
                }}
                productName="Twilio Comms"
                productStrapline="SMS, Voice & Video"
                productIcon={<LogoTwilioIcon decorative />}
              />
              <ProductSwitcherItem
                {...productSwitcher}
                name="product"
                value="segment"
                checked={false}
                onChange={() => {
                  window.location.href = segmentUrl;
                }}
                productName="Twilio Segment"
                productStrapline="Customer data platform"
                productIcon={<ProductSegmentIcon decorative />}
              />
              <ProductSwitcherItem
                {...productSwitcher}
                name="product"
                value="sendgrid"
                checked={true}
                productName="Twilio SendGrid"
                productStrapline="Email delivery & API"
                productIcon={<ProductEmailAPIIcon decorative />}
              />
            </ProductSwitcher>
          </>
        )}
        {showUserMenu && (
          <UserDialogContainer name="User Name" icon={UserIcon} baseId="i-am-user-dialog">
            <UserDialog aria-label="user menu" data-testid="basic-user-dialog">
              <UserDialogUserInfo tabIndex={-1}>
                <span style={{ wordBreak: "break-all" }}>
                  <UserDialogUserName>{name}</UserDialogUserName>
                </span>
                <span style={{ wordBreak: "break-all" }}>
                  <UserDialogUserEmail>{email}</UserDialogUserEmail>
                </span>
                <Button
                  onClick={() => {
                    window.open(userSettingsUrl, "_blank");
                  }}
                  variant="link"
                  tabIndex={-1}>
                  <span style={{ fontWeight: 400 }}>User Settings</span>
                </Button>
              </UserDialogUserInfo>
              <UserDialogList {...userDialogList} aria-label="User menu actions">
                <span
                  onClick={() => {
                    externalNavigation.toAccountDetails();
                  }}>
                  <UserDialogListItem {...userDialogList} tabIndex={-1}>
                    <UserIcon decorative />
                    Sendgrid Preferences
                  </UserDialogListItem>
                </span>
                <UserDialogSeparator />
                <span
                  onClick={() => {
                    externalNavigation.toGuide();
                  }}>
                  <UserDialogListItem {...userDialogList} tabIndex={-1}>
                    <DocumentationIcon decorative />
                    Setup Guide
                  </UserDialogListItem>
                </span>
                <span
                  onClick={() => {
                    window.open(supportUrl, "_blank");
                  }}>
                  <UserDialogListItem {...userDialogList} tabIndex={-1}>
                    <SupportIcon decorative />
                    Help & Support
                  </UserDialogListItem>
                </span>
                <UserDialogSeparator />
                <UserDialogListItem {...userDialogList} tabIndex={-1} href="/logout">
                  <LogOutIcon decorative />
                  Log out
                </UserDialogListItem>
              </UserDialogList>
            </UserDialog>
          </UserDialogContainer>
        )}
      </TopbarActions>
    </Topbar>
  );
};
