import { useContext } from "react";
import {
  SendGridRouterContext,
  SendGridRouterContextInterface,
  SendGridSessionContext,
  SendGridSessionContextInterface,
} from "./SendGridAuthContext";

/**
 * ```js
 * const {
 *   // SendGrid Auth state:
 *   isAuthenticated: boolean,
 *   makoToken?: string,
 *   // SendGrid Set Session values.
 *   setSession,
 *   clearSession,
 * } = useSendGridSession();
 * ```
 *
 * Use the `useSendGridSession` hook in your components to access the auth state and session methods.
 * Say you needed MakoToken, or alternatively you could modify this object to do what you want when setting the session
 * or clearing it.
 */
export const useSendGridSession = (
  context = SendGridSessionContext
): SendGridSessionContextInterface => useContext(context) as SendGridSessionContextInterface;

/**
 * ```js
 * const {
 *   // SendGrid navigate methods:
 *   navigate,
 * } = useSendGridRouter();
 * ```
 *
 * Use the `useSendGridRouter` hook in your components to access navigate after you have set the session.
 */
export const useSendGridRouter = (
  context = SendGridRouterContext
): SendGridRouterContextInterface => useContext(context) as SendGridRouterContextInterface;
