import { useCallback, useContext, useMemo } from "react";
import { useCookies } from "react-cookie";
import { cookieSetOptions } from "../cookieSetOptions";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { SESSION_USERNAME_KEY, UNIFIED_USER_INFO_COOKIE_NAME } from "./constants";
import { UnifiedAuthContext, UnifiedAuthContextInterface } from "./context";

/**
 * Use the `useUnifiedAuth` hook in your components to access the auth state and methods.
 */
export const useUnifiedAuth = (context = UnifiedAuthContext): UnifiedAuthContextInterface =>
  useContext(context) as UnifiedAuthContextInterface;

export interface UnifiedUserInfo {
  given_name: string;
  family_name: string;
  email: string;
}

export interface UnifiedUserInfoCookieInterface {
  setUnifiedUserInfo: (info: UnifiedUserInfo) => void;
  removeUnifiedUserInfo: () => void;
}

export const useUnifiedUserInfoCookie = (): UnifiedUserInfoCookieInterface => {
  const [, setCookie, removeCookie] = useCookies([UNIFIED_USER_INFO_COOKIE_NAME]);
  const setUnifiedUserInfo = useCallback(
    (info: UnifiedUserInfo) =>
      setCookie(UNIFIED_USER_INFO_COOKIE_NAME, info, {
        sameSite: "strict",
      }),
    [setCookie]
  );

  const removeUnifiedUserInfo = useCallback(
    () => removeCookie(UNIFIED_USER_INFO_COOKIE_NAME, cookieSetOptions()),
    [removeCookie]
  );

  return useMemo(
    () => ({
      setUnifiedUserInfo,
      removeUnifiedUserInfo,
    }),
    [removeUnifiedUserInfo, setUnifiedUserInfo]
  );
};

export function useSessionUsername() {
  return useSessionStorage<string>(SESSION_USERNAME_KEY, "");
}
