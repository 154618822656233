import React from "react";
import {
  ExternalLocationContext,
  ExternalLocationContextObject,
  ExternalNavigateContext,
  ExternalNavigationContextObject,
  ExternalNavigator,
  ExternalStorage,
} from "./context";
import { ExternalNavigateOptions, To, useExternalHref } from "./hooks";

export const DEFAULT_REDIRECT_KEY = "default_redirect_to";

export class SessionStorage implements ExternalStorage {
  public set(key: string, entry: string) {
    window.sessionStorage.setItem(key, JSON.stringify(entry));
  }

  public get(key: string): string | undefined {
    const json = window.sessionStorage.getItem(key);

    if (!json) return;

    try {
      const payload = JSON.parse(json) as string;
      return payload;
    } catch (e) {
      return;
    }
  }

  public remove(key: string) {
    window.sessionStorage.removeItem(key);
  }
}

/**
 * ExternalNavigationProviderProps properties for the ExternalNavigationProvider
 */
export interface ExternalNavigationProviderProps {
  basename?: string;
  children?: React.ReactNode;
  context?: React.Context<ExternalNavigationContextObject>;
  locationContext?: React.Context<ExternalLocationContextObject>;
  location?: Location;
  store?: ExternalStorage;
}

export function ExternalNavigationProvider(opts: ExternalNavigationProviderProps): JSX.Element {
  const {
    basename = "/",
    children = null,
    context = ExternalNavigateContext,
    locationContext = ExternalLocationContext,
    location = window.location,
  } = opts;
  let store = React.useMemo(() => new SessionStorage(), []);
  let locationContextValue = React.useMemo(() => {
    return {
      location,
    };
  }, [location]);
  let createUrl = React.useCallback(
    (to: string | URL, opts?: ExternalNavigateOptions) => {
      let url = new URL(to, basename);
      let key = DEFAULT_REDIRECT_KEY;
      if (opts?.redirect) {
        key = opts?.redirect;
      }
      const redirect = store.get(key);
      if (redirect) {
        if (opts?.encode) {
          url.searchParams.append("redirect_to", redirect);
        } else {
          url = redirect.startsWith("https://") ? new URL(redirect) : new URL(redirect, basename);
        }
        store.remove(key);
      }
      return url;
    },
    [basename, store]
  );
  let externalNavigator = React.useMemo((): ExternalNavigator => {
    return {
      push: (to: To, opts?: ExternalNavigateOptions): void => {
        let url = createUrl(to, opts);
        location.assign(url.toString());
      },
      replace: (to: To, opts?: ExternalNavigateOptions): void => {
        let url = createUrl(to, opts);
        location.replace(url.toString());
      },
      createHref: (to: To, opts?: ExternalNavigateOptions): string => {
        let url = createUrl(to, opts);
        return url.toString();
      },
    };
  }, [createUrl, location]);
  let navigationContext = React.useMemo((): ExternalNavigationContextObject => {
    return {
      basename,
      externalNavigator,
      store,
    };
  }, [basename, externalNavigator, store]);
  return (
    <>
      <context.Provider value={navigationContext}>
        <locationContext.Provider value={locationContextValue}>{children}</locationContext.Provider>
      </context.Provider>
    </>
  );
}

export interface ExternalLinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> {
  to: To;
  target?: string;
  rel?: string;
  context?: React.Context<ExternalNavigationContextObject>;
}

export const ExternalLink = React.forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  function ExternalWithRef(
    { to, context = ExternalNavigateContext, children = null, target, rel },
    ref
  ) {
    let href = useExternalHref(to, context);
    return (
      <a href={href} ref={ref} target={target} rel={rel}>
        {children}
      </a>
    );
  }
);
