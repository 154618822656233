import { useCallback, useEffect, useState } from "react";

import { Anchor, Box, Button, Heading, Text } from "@twilio-paste/core";
import { ArrowForwardIcon } from "@twilio-paste/icons/esm/ArrowForwardIcon";

import { AccountLinkCard } from "./components/AccountLinkCard";
import { ConfirmationModal } from "./components/ConfirmationModal";
import { InformationCard } from "./components/InformationCard";

import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../Loading";
import { NavbarContainer } from "../../NavbarContainer";
import { SendGridSessionContext, useSendGridSession } from "../../sendgrid-auth";
import type { UnifiedAccountLinkType } from "../../unified-auth";
import { useUnifiedAuth } from "../../unified-auth";
import { UnifiedProvisionEligibilityResult } from "../../unified-auth/context";

export const PromptUnifiedAccountLink = () => {
  const { makoToken } = useSendGridSession(SendGridSessionContext);
  const {
    redirectToUnifiedAuthWithLinking,
    skipUnifiedAccountLinking,
    fetchUnifiedAccountLinkingEligibility,
  } = useUnifiedAuth();
  const [linkType, setLinkType] = useState<UnifiedAccountLinkType | undefined>(undefined);

  // checks eligibility on page load as user can be redirected here
  // from sendgrid's validate 2fa page.
  const {
    data: unifiedAccountLinkingEligibility,
    isLoading,
    isSuccess,
  } = useQuery<UnifiedProvisionEligibilityResult>({
    enabled: Boolean(makoToken),
    queryKey: ["unifiedProvisionPlan"],
    queryFn: async () => fetchUnifiedAccountLinkingEligibility(makoToken!),
    staleTime: 0,
  });

  const shouldShowLoadingSpinner =
    isLoading || !isSuccess || (isSuccess && !unifiedAccountLinkingEligibility.eligible);
  const shouldSkipUnifiedAccountLinking =
    !isSuccess || (isSuccess && !unifiedAccountLinkingEligibility.eligible);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (shouldSkipUnifiedAccountLinking) {
      skipUnifiedAccountLinking();
    }
  }, [isLoading, shouldSkipUnifiedAccountLinking, skipUnifiedAccountLinking]);

  const onModalConfirm = useCallback(() => {
    if (linkType) {
      redirectToUnifiedAuthWithLinking(
        linkType,
        unifiedAccountLinkingEligibility?.eligible
          ? unifiedAccountLinkingEligibility.email
          : undefined
      );
    }
    setLinkType(undefined);
  }, [unifiedAccountLinkingEligibility, linkType, redirectToUnifiedAuthWithLinking]);

  const onModalCancel = useCallback(() => {
    setLinkType(undefined);
  }, []);

  const onLinkByLoginSelected = useCallback(() => {
    setLinkType("login");
  }, []);

  const onLinkBySignupSelected = useCallback(() => {
    setLinkType("signup");
  }, []);

  if (shouldShowLoadingSpinner) {
    return <Loading />;
  }

  return (
    <>
      <NavbarContainer showProductSwitcher={false} showUserMenu={false} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        alignItems={"center"}
        paddingX={"space100"}
        paddingTop={"space90"}
        marginBottom={"space200"}
        height={"100vh"}>
        <Box position={"relative"} marginLeft={"auto"} paddingBottom={"space170"}>
          <Button
            variant={"link"}
            size={"reset"}
            onClick={skipUnifiedAccountLinking}
            data-role="skip-button">
            Skip this process for now
            <ArrowForwardIcon decorative title={"skip this process for now"} />
          </Button>
        </Box>
        <Box paddingTop={"space160"}>
          <Heading as={"h1"} variant={"heading20"} marginBottom={"space0"}>
            A single login across all your Twilio products
          </Heading>
        </Box>
        <Box display={"flex"} columnGap={"space110"} paddingY={"space110"}>
          <AccountLinkCard onLogin={onLinkByLoginSelected} onSignup={onLinkBySignupSelected} />
          <InformationCard />
        </Box>
        <Text as={"p"} fontSize={"fontSize20"} color={"colorTextWeak"}>
          By continuing, you agree to the{" "}
          <Anchor href={"https://www.twilio.com/legal/tos"}>Terms of Service</Anchor> and{" "}
          <Anchor href={"https://www.twilio.com/legal/privacy"}>Privacy Notice</Anchor>.
        </Text>
      </Box>
      <ConfirmationModal
        showModal={linkType !== undefined}
        onConfirm={onModalConfirm}
        onCancel={onModalCancel}
      />
    </>
  );
};
