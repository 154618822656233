import { useState } from "react";

export const useFormState = <T extends HTMLElement>(incomingState: string) => {
  const [state, setState] = useState(incomingState);

  const formSetState = (e: React.FormEvent<any>) => {
    setState(e.currentTarget.value);
  };

  const returnstuff: [string, React.Dispatch<string>, (e: React.FormEvent<T>) => void] = [
    state,
    setState,
    formSetState,
  ];

  return returnstuff;
};
