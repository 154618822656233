export const Button = ({
  dataRole,
  isLoading = false,
  isDisabled = false,
  className = "",
  children,
}: {
  dataRole: string;
  isLoading?: boolean;
  isDisabled: boolean;
  className?: string;
  children: React.ReactNode;
}) => (
  <button
    data-role={dataRole}
    className={`btn btn-primary has-icon login-btn ${className}
    ${isDisabled ? "is-disabled" : ""}
    ${isLoading ? "is-loading" : ""}
    `}
    type="submit"
    disabled={isDisabled}>
    {isLoading && (
      <svg className="sg-loader loader-on-dark is-small">
        <rect className="logo-square bottom-left" width="5" height="5" />
        <rect className="logo-square bottom-middle" width="5" height="5" />
        <rect className="logo-square middle" width="5" height="5" />
        <rect className="logo-square top-middle" width="5" height="5" />
        <rect className="logo-square top-right" width="5" height="5" />
        <rect className="logo-square middle-left" width="5" height="5" />
        <rect className="logo-square middle-right" width="5" height="5" />
      </svg>
    )}
    {children}
  </button>
);
