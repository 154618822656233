import { Anchor } from "@twilio-paste/core";
import { Avatar } from "@twilio-paste/core/avatar";
import { Box } from "@twilio-paste/core/box";
import { Heading } from "@twilio-paste/core/heading";
import { Stack } from "@twilio-paste/core/stack";
import { Text } from "@twilio-paste/core/text";
import { DirectoryIcon } from "@twilio-paste/icons/esm/DirectoryIcon";
import { LinkExternalIcon } from "@twilio-paste/icons/esm/LinkExternalIcon";

export const InformationCard = () => {
  const unifiedAccountLinkingDocsUrl = `${process.env.REACT_APP_TWILIO_BASE_URL}/docs/sendgrid/ui/account-and-settings/twilio-unified-login-overview#user-linking`;
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      padding={"space100"}
      borderStyle={"solid"}
      borderWidth={"borderWidth10"}
      borderRadius={"borderRadius30"}
      borderColor={"colorBorderWeaker"}
      boxShadow={"shadowCard"}
      height={"390px"}>
      <Stack orientation={"vertical"} spacing={"space60"}>
        <Heading variant={"heading50"} as={"span"} marginBottom={"space0"}>
          What’s improving
        </Heading>
        <Box display={"flex"} columnGap={"space30"}>
          <Avatar name={"improvement1"} color={"decorative20"} icon={DirectoryIcon} />
          <Box maxWidth={"285px"}>
            <Text as={"p"} fontSize={"fontSize30"}>
              Seamless sign up to Twilio Comms (Messaging, Voice & Flex) and Segment
            </Text>
          </Box>
        </Box>
        <Box display={"flex"} columnGap={"space30"}>
          <Avatar name={"improvement1"} color={"decorative10"} icon={DirectoryIcon} />
          <Box maxWidth={"285px"}>
            <Text as={"p"} fontSize={"fontSize30"}>
              Single user profile across all Twilio products
            </Text>
          </Box>
        </Box>
        <Box display={"flex"} columnGap={"space30"}>
          <Avatar name={"improvement1"} color={"decorative30"} icon={DirectoryIcon} />
          <Box maxWidth={"285px"}>
            <Text as={"p"} fontSize={"fontSize30"}>
              Use any authenticator app for 2FA
            </Text>
          </Box>
        </Box>
        <Box display={"flex"} columnGap={"space30"}>
          <Avatar name={"improvement1"} color={"decorative40"} icon={DirectoryIcon} />
          <Box maxWidth={"285px"}>
            <Text as={"p"} fontSize={"fontSize30"}>
              Access multiple parent SendGrid accounts (<em>coming soon</em>)
            </Text>
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"row"} columnGap={"space20"} alignItems={"center"}>
          <Anchor href={unifiedAccountLinkingDocsUrl} target="_blank">
            Learn more
          </Anchor>
          <LinkExternalIcon decorative color={"colorTextLink"} />
        </Box>
      </Stack>
    </Box>
  );
};
