import React, { useCallback, useMemo } from "react";
import { useExternalNavigate } from "../hooks/useExternalNavigate";
import {
  SendGridNavigateOptions,
  SendGridRouterContext,
  SendGridRouterContextInterface,
  SendGridSessionContext,
  SendGridSessionContextInterface,
  SendGridSetSessionOptions,
} from "./SendGridAuthContext";
import { useMakoToken } from "./useMakoToken";
import { useMFASettingIsVerified } from "./useMFASettingIsVerified";
import { usePasswordResetRequired } from "./usePasswordResetRequired";
import { seedRandomSessionIdentifier, useSessionIdentifier } from "./useSessionIdentifier";
import {
  Setup2FARequiredCheckpoint,
  useSetup2FARequired,
  useSetup2FARequiredCheckpoint,
} from "./useSetup2FARequired";

export interface SendGridSessionProviderOptions {
  children: React.ReactNode;
  context?: React.Context<SendGridSessionContextInterface>;
}

export const SendGridSessionProvider = (opts: SendGridSessionProviderOptions): JSX.Element => {
  const { children, context = SendGridSessionContext } = opts;
  const { makoToken, setMakoToken, removeMakoToken } = useMakoToken();
  const [setup2FARequired, setSetup2FARequired, removeSetup2FARequired] = useSetup2FARequired();
  const [mfaSettingIsVerified, setMFASettingIsVerified, removeMFASettingIsVerified] =
    useMFASettingIsVerified();
  const [, , removeSetup2FARequiredCheckpoint] = useSetup2FARequiredCheckpoint();
  const [passwordResetRequired, setPasswordResetRequired, removePasswordResetRequired] =
    usePasswordResetRequired();
  const [sessionIdentifier, setSessionIdentifier, removeSessionIdentifier] = useSessionIdentifier();

  const isAuthenticated = useMemo(() => {
    return !!makoToken;
  }, [makoToken]);

  const resetSessionIdentifier = useCallback(() => {
    // If session identifier is not set then go ahead and generate it and set it.
    if (!sessionIdentifier) {
      setSessionIdentifier(seedRandomSessionIdentifier());
    }
  }, [sessionIdentifier, setSessionIdentifier]);

  const setSession = useCallback(
    (opts: SendGridSetSessionOptions) => {
      const {
        token,
        setup2FARequired = false,
        passwordResetRequired = false,
        mfaSettingIsVerified = false,
      } = opts;
      setMakoToken(token);
      resetSessionIdentifier();
      setSetup2FARequired(setup2FARequired);
      setPasswordResetRequired(passwordResetRequired);
      setMFASettingIsVerified(mfaSettingIsVerified);
    },
    [
      setMakoToken,
      resetSessionIdentifier,
      setSetup2FARequired,
      setPasswordResetRequired,
      setMFASettingIsVerified,
    ]
  );

  const clearSession = useCallback(() => {
    removePasswordResetRequired();
    removeSessionIdentifier();
    removeSetup2FARequired();
    removeSetup2FARequiredCheckpoint();
    removeMFASettingIsVerified();
    removeMakoToken();
  }, [
    removePasswordResetRequired,
    removeSessionIdentifier,
    removeSetup2FARequired,
    removeSetup2FARequiredCheckpoint,
    removeMFASettingIsVerified,
    removeMakoToken,
  ]);

  const navigationOptions = useMemo(() => {
    return {
      setup2FARequired,
      passwordResetRequired,
      mfaSettingIsVerified,
    };
  }, [mfaSettingIsVerified, passwordResetRequired, setup2FARequired]);

  const contextValue = useMemo(() => {
    return {
      isAuthenticated,
      makoToken,
      setSession,
      clearSession,
      navigationOptions,
    };
  }, [isAuthenticated, makoToken, setSession, clearSession, navigationOptions]);
  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export interface SendGridRouterProviderOptions {
  children: React.ReactNode;
  context?: React.Context<SendGridRouterContextInterface>;
}

export const SendGridRouterProvider = (opts: SendGridRouterProviderOptions): JSX.Element => {
  const { children, context = SendGridRouterContext } = opts;
  const [, setSetup2FARequiredCheckpoint] = useSetup2FARequiredCheckpoint();
  const externalNavigate = useExternalNavigate();

  const navigate = useCallback(
    (opt: SendGridNavigateOptions) => {
      const {
        setup2FARequired,
        mfaSettingIsVerified,
        passwordResetRequired,
        redirectToOAuth,
        skipSecurityCheck,
      } = opt;
      // In all error cases, either redirect to password reset (if required)
      // or redirect to the security checkup.
      // The security checkup will check the 2FA status again
      // and render the appropriate flow based on the result.
      if (setup2FARequired) {
        setSetup2FARequiredCheckpoint(Setup2FARequiredCheckpoint.EMAIL);
        externalNavigate.toSetup2FARequiredEmailCheckpoint();
        return;
      } else if (mfaSettingIsVerified) {
        externalNavigate.toValidate2FA();
        return;
      } else if (passwordResetRequired) {
        externalNavigate.toPasswordReset();
        return;
      } else if (redirectToOAuth) {
        externalNavigate.toOAuthAuthorize();
        return;
      } else if (skipSecurityCheck) {
        externalNavigate.toDashboard();
        return;
      } else {
        externalNavigate.toSecurityCheckup();
        return;
      }
    },
    [externalNavigate, setSetup2FARequiredCheckpoint]
  );

  const contextValue = useMemo(() => {
    return {
      navigate,
    };
  }, [navigate]);
  return <context.Provider value={contextValue}>{children}</context.Provider>;
};
