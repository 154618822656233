import { useAuth0 } from "@auth0/auth0-react";
import { Navbar } from "./Navbar";

type NavbarContainerProps = {
  showProductSwitcher: boolean;
  showUserMenu: boolean;
};

export const NavbarContainer = ({ showProductSwitcher, showUserMenu }: NavbarContainerProps) => {
  const { user } = useAuth0();
  const name = `${user?.given_name ?? ""} ${user?.family_name ?? ""}`;
  const email = user?.email ?? "";

  return (
    <Navbar
      showProductSwitcher={showProductSwitcher}
      showUserMenu={showUserMenu}
      name={name}
      email={email}
    />
  );
};
