import { useCallback, useMemo } from "react";
import { useCookies } from "react-cookie";
import { cookieSetOptions } from "../cookieSetOptions";
import { MAKO_COOKIE_NAME } from "./constants";

export function useMakoToken() {
  const [cookies, setCookie, removeCookie] = useCookies([MAKO_COOKIE_NAME]);
  const makoToken = useMemo(() => cookies.mako_auth_token, [cookies]);

  const setMakoToken = useCallback(
    (token: string) => {
      if (!token) {
        throw new Error("call removeMakoToken instead");
      }
      setCookie(MAKO_COOKIE_NAME, token);
    },
    [setCookie]
  );

  const removeMakoToken = useCallback(() => {
    removeCookie(MAKO_COOKIE_NAME, cookieSetOptions());
  }, [removeCookie]);

  return { makoToken, setMakoToken, removeMakoToken };
}
