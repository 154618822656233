import React from "react";
import {
  ExternalLocationContext,
  ExternalNavigateContext,
  ExternalNavigationContextObject,
} from "./context";
import { invariant } from "./utils";

export type To = string | URL;

/**
 * ExternalNavigateOptions is the options related to navigation.
 */
export interface ExternalNavigateOptions {
  /**
   * Replace means to replace the location in history rather than just navigate to that new point.
   */
  replace?: boolean;
  /**
   * The named redirect session storage item.
   */
  redirect?: string;
  /**
   * Encode the redirect into the endpoint rather than using the redirect instead.
   */
  encode?: boolean;
}

export interface ExternalNavigateFunction {
  (to: To, options?: ExternalNavigateOptions): void;
}

/**
 * useExternalNavigate returns a function that allows you to navigate to a specific endpoint.
 * @param context is an optional param to support multiple contexts.
 */
export const useExternalNavigate = (
  context = ExternalNavigateContext
): ExternalNavigateFunction => {
  let { externalNavigator } = React.useContext(context);

  const externalNavigate = React.useCallback(
    (url: To, options?: ExternalNavigateOptions) => {
      if (options?.replace) {
        externalNavigator.replace(url, options);
      } else {
        externalNavigator.push(url, options);
      }
    },
    [externalNavigator]
  );
  return externalNavigate;
};

/**
 * useExternalLocation returns a function that allows you to location to a specific endpoint.
 * @param context
 */
export function useExternalLocation(context = ExternalLocationContext): Location {
  invariant(
    useInExternalLocationContext(context),
    `useExternalLocation() may be used only inside a <External> component.`
  );

  return React.useContext(context).location;
}

/**
 * useInExternalLocationContext returns true if this component is a descendant of a `<ExternalNavigationProvider>`.
 */
export function useInExternalLocationContext(context = ExternalLocationContext): boolean {
  return React.useContext(context) !== null;
}

/**
 * useExternalHref Returns the full href from the given "to" value.
 */
export function useExternalHref(
  to: To,
  context = ExternalNavigateContext,
  opts?: ExternalNavigateOptions
): string {
  invariant(
    useInExternalLocationContext(),
    `useExternalHref() may be used only in the context of a <ExternalLocationContextProvider>`
  );

  let { externalNavigator } = React.useContext(context);

  return externalNavigator.createHref(to, opts);
}

/**
 * useExternalNavigation is a way to get the ExternalNavigationContext
 */
export const useExternalNavigation = (context = ExternalNavigateContext) =>
  React.useContext(context) as ExternalNavigationContextObject;
