import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
  Paragraph,
} from "@twilio-paste/core";
import { useUID } from "react-uid";

import { PartnerSolutionsImage } from "../illustrations/PartnerSolutions";
import { TwilioSendGridLinking } from "../illustrations/TwilioSendgridLinking";

export type ConfirmationModalProps = {
  showModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmationModal = ({ showModal, onConfirm, onCancel }: ConfirmationModalProps) => {
  const ModalId = useUID();

  return (
    <Modal
      isOpen={showModal}
      onDismiss={onCancel}
      size={"default"}
      ariaLabelledby={ModalId}
      data-role="confirmation-modal">
      <ModalHeader>
        <div />
      </ModalHeader>
      <ModalBody>
        <Box>
          <Box
            display={"flex"}
            flex={1}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingBottom={"space90"}>
            <Box textAlign={"start"} maxWidth={"293px"}>
              <ModalHeading as="h3" id={ModalId}>
                Before you link ensure that
              </ModalHeading>
              <Paragraph marginBottom="space0">
                Your SendGrid email matches the Twilio email you&apos;ll be linking to.
              </Paragraph>
            </Box>
            <TwilioSendGridLinking />
          </Box>
          <Box display={"flex"} flex={1} justifyContent={"space-between"} alignItems={"center"}>
            <PartnerSolutionsImage />
            <Box textAlign={"start"} maxWidth={"327px"}>
              <ModalHeading as="h3" id={ModalId}>
                What will happen after
              </ModalHeading>
              <Paragraph marginBottom="space0">
                You will sign in to SendGrid with your Twilio login credentials. Your user profile
                and 2FA settings will be managed through Twilio.
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <Button variant="secondary" onClick={onCancel} data-role="cancel-button">
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm} data-role="confirm-button">
            Continue
          </Button>
        </ModalFooterActions>
      </ModalFooter>
    </Modal>
  );
};
