import { Box } from "@twilio-paste/core";

export const Line = () => {
  return (
    <Box
      margin="space0"
      borderLeftWidth={"borderWidth10"}
      borderColor="colorBorderWeak"
      borderStyle="solid"
      flexGrow={1}
      as="hr"
    />
  );
};
