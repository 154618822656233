import { useCallback, useMemo } from "react";
import { useCookies } from "react-cookie";
import { cookieSetOptions } from "../cookieSetOptions";
import { MFA_SETTING_IS_VERIFIED } from "./constants";

export const useMFASettingIsVerified = () => {
  const [cookies, setCookie, removeCookie] = useCookies([MFA_SETTING_IS_VERIFIED]);

  const mfaSettingIsVerified = useMemo(() => {
    return cookies.mfa_setting_is_verified;
  }, [cookies]);

  const setMFASettingIsVerified = useCallback(
    (value: string) => {
      setCookie(MFA_SETTING_IS_VERIFIED, value);
    },
    [setCookie]
  );

  const removeMFASettingIsVerified = useCallback(() => {
    removeCookie(MFA_SETTING_IS_VERIFIED, cookieSetOptions());
  }, [removeCookie]);
  return useMemo(
    () => [mfaSettingIsVerified, setMFASettingIsVerified, removeMFASettingIsVerified],
    [mfaSettingIsVerified, setMFASettingIsVerified, removeMFASettingIsVerified]
  );
};
