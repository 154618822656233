import { User } from "@auth0/auth0-react";

export const EventType = {
  authentication: "authentication",
};

export const Page = {
  identifier: "identifier",
  password: "password",
  callback: "callback",
};

export const Section = {
  password: "password",
  identifier: "identifier",
  callback: "callback",
};

export const UnifiedAuthEvents = {
  FailedLoginAttemptBasic: (username?: string) => ({
    event: "failed_login_attempt",
    properties: {
      username: username,
      page: Page.password,
      section: Section.password,
      eventType: EventType.authentication,
      authType: "password",
      description: "Invalid username or password",
    },
  }),
  BasicAuthLoginComplete: (username?: string) => ({
    event: "basic_login_complete",
    properties: {
      username: username,
      page: Page.password,
      section: Section.password,
      eventType: EventType.authentication,
      description: "The login is complete for a basic auth user",
    },
  }),
  Auth0LoginStart: (email?: string) => ({
    event: "auth0_login_start",
    properties: {
      email: email,
      page: Page.identifier,
      section: Section.identifier,
      eventType: EventType.authentication,
      description: "The login is started for an auth0 user",
    },
  }),
  Auth0LoginComplete: (user?: User) => ({
    event: "auth0_login_complete",
    properties: {
      firstName: user?.given_name,
      lastName: user?.family_name,
      email: user?.email,
      unifiedUserId: user?.["https://twilio.com/unified_user_id"],
      page: Page.identifier,
      section: Section.identifier,
      eventType: EventType.authentication,
      description: "The login is complete for an auth0 user",
    },
  }),
  SendGridSignupComplete: (isUnifiedSignup: boolean, user?: User) => ({
    event: "sendgrid_signup_complete",
    properties: {
      firstName: user?.given_name,
      lastName: user?.family_name,
      email: user?.email,
      unifiedUserId: user?.["https://twilio.com/unified_user_id"],
      isUnifiedSignup: isUnifiedSignup,
      page: Page.callback,
      section: Section.callback,
      eventType: EventType.authentication,
      description: "The signup is complete for creating a new sendgrid user",
    },
  }),
};
