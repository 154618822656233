import { createContext } from "react";

export interface SendGridSetSessionOptions {
  token: string;
  setup2FARequired?: boolean;
  passwordResetRequired?: boolean;
  mfaSettingIsVerified?: boolean;
  redirectToOAuth?: boolean;
  skipSecurityCheck?: boolean;
}

export interface SendGridNavigateOptions {
  setup2FARequired?: boolean;
  passwordResetRequired?: boolean;
  mfaSettingIsVerified?: boolean;
  redirectToOAuth?: boolean;
  skipSecurityCheck?: boolean;
}

/**
 * The auth state which, when combined with the auth methods.
 */
export interface SendGridAuthState {
  isAuthenticated: boolean;
  makoToken?: string;
}

export interface SendGridSessionContextInterface extends SendGridAuthState {
  /**
   * ```js
   * sendgrid.setSession
   * ```
   * setSession takes in opts set up the session information you need.
   */
  setSession: (opts: SendGridSetSessionOptions) => void;

  /**
   * ```js
   * sendgrid.clearSession;
   * ```
   * Clears the application session for SendGrid.
   */
  clearSession: () => void;

  /**
   * ```js
   * sendgrid.navigationOptions;
   * ```
   * Returns the navigation options for the current session.
   */
  navigationOptions: SendGridNavigateOptions;
}

export interface SendGridRouterContextInterface {
  /**
   * navigates takes in opts and navigates you to the correct location.
   */
  navigate: (opts: SendGridNavigateOptions) => void;
}

export const initialSendGridAuthState = {
  isAuthenticated: false,
  navigationOptions: {},
};

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error("You forgot to wrap your component in <SendGridAuthProvider>.");
};

/**
 * @ignore
 */
export const initialSendGridSessionContext = {
  ...initialSendGridAuthState,
  setSession: stub,
  clearSession: stub,
};

/**
 * @ignore
 */
export const initialSendGridRouterContext = {
  navigate: stub,
};

export const SendGridSessionContext = createContext<SendGridSessionContextInterface>(
  initialSendGridSessionContext
);

export const SendGridRouterContext = createContext<SendGridRouterContextInterface>(
  initialSendGridRouterContext
);
