import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { Loading } from "../../Loading";
import { useSendGridSession } from "../../sendgrid-auth";
import { useUnifiedAuth } from "../../unified-auth";
import { fetchCAMAccount, fetchLogoutConfig } from "./fetch";

const logoutPathQuery = (makoToken: string | undefined) => ({
  queryKey: ["logoutPath", makoToken],
  queryFn: async () => {
    try {
      // If a mako token is not defined then always just return '/';
      if (typeof makoToken === "undefined") {
        return "/";
      }
      const logoutConfig = await fetchLogoutConfig();
      const camAccount = await fetchCAMAccount(makoToken);
      const catalogOrg = camAccount?.catalog_org;
      return (logoutConfig && logoutConfig[catalogOrg])?.sso_logout || `/`;
    } catch (error) {
      // If something goes wrong just return '/'
      return "/";
    }
  },
});

export const Logout = () => {
  const { makoToken } = useSendGridSession();
  const { data: path } = useQuery(logoutPathQuery(makoToken));
  const { logout } = useUnifiedAuth();
  const didLogout = useRef(false);

  useEffect(() => {
    (async () => {
      if (!path) {
        return;
      }
      if (didLogout.current) {
        return;
      }
      didLogout.current = true;
      await logout({ returnTo: path });
    })();
  }, [logout, path, didLogout]);

  return <Loading />;
};
