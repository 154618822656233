import { ChangeEvent, CSSProperties, useEffect, useRef, useState } from "react";

export const TextInput = ({
  value,
  onChange,
  label,
  type = "text",
  id,
  hidden = false,
  isDisabled = false,
  autoComplete = "off",
  autoFocus = false,
  containerRole,
}: {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  type?: string;
  id: string;
  hidden?: boolean;
  isDisabled?: boolean;
  autoComplete?: "off" | "current-password" | "username";
  autoFocus?: boolean;
  containerRole: string;
}) => {
  const [isFocused, setFocused] = useState(false);
  const inpuEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      inpuEl?.current?.focus();
    }
  }, [autoFocus]);

  const hasValue = (value !== "" || isFocused) && !hidden;
  const hiddenStyles: CSSProperties = hidden ? { position: "absolute", left: -11111111111 } : {};

  return (
    <>
      <div
        className={`input-text-wrap is-full-width ${hasValue ? "has-value" : ""}`}
        style={hiddenStyles}
        role={containerRole}>
        <label className="input-text-label" htmlFor={id}>
          {label}
        </label>

        <input
          tabIndex={hidden ? -1 : 2}
          className="input-text"
          id={id}
          name={id}
          type={type}
          spellCheck={false}
          autoComplete={autoComplete}
          value={value}
          ref={inpuEl}
          onChange={(e) => {
            onChange(e);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          // eslint-disable-next-line
          role="input"
        />
      </div>
    </>
  );
};
export default TextInput;
