import { Box, Button, Heading, Text } from "@twilio-paste/core";
import { LinkIcon } from "@twilio-paste/icons/esm/LinkIcon";

import { Line } from "./Line";
import { LogoAvatar } from "./LogoAvatar";

export type AccountLinkCardProps = {
  onLogin: () => void;
  onSignup: () => void;
};

export const AccountLinkCard = ({ onLogin, onSignup }: AccountLinkCardProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      padding={"space100"}
      borderStyle={"solid"}
      borderWidth={"borderWidth10"}
      borderRadius={"borderRadius30"}
      borderColor={"colorBorderWeaker"}
      boxShadow={"shadowCard"}
      height={"390px"}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        columnGap={"space40"}
        paddingBottom={"space60"}>
        <LogoAvatar logo="sendgrid" />
        <LinkIcon decorative color={"colorTextWeak"} />
        <LogoAvatar logo={"twilio"} />
      </Box>
      <Box paddingBottom={"space60"} marginX={"auto"}>
        <Button
          variant="primary"
          aria-disabled={false}
          onClick={onSignup}
          data-role={"signup-button"}>
          Create new Twilio user
        </Button>
      </Box>
      <Box display={"flex"} alignItems={"center"} paddingBottom={"space60"}>
        <Line />
        <Text as={"span"} color={"colorTextInverseWeaker"} marginX={"space50"}>
          OR
        </Text>
        <Line />
      </Box>
      <Box textAlign={"center"} width={"325px"}>
        <Heading variant={"heading50"} as={"span"}>
          Already have a Twilio user to link?
        </Heading>
        <Button
          variant="secondary"
          aria-disabled={false}
          onClick={onLogin}
          data-role={"login-button"}>
          Login
        </Button>
      </Box>
    </Box>
  );
};
