export interface AuthorizedFunction {
  (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
}

export function authorized(token: string): AuthorizedFunction {
  if (!token) {
    throw Error("token must be set");
  }
  let func = (input: RequestInfo | URL, opts?: RequestInit) => {
    let init = {
      ...opts,
      headers: {
        ...opts?.headers,
        Authorization: `token ${token}`,
      },
    };
    return fetch(input, init);
  };
  return func;
}
