export function Error({ message }: { message: string }) {
  return (
    <>
      <div id="login-error-alert-container" className="alert alert-danger" role="alert">
        <div
          style={{
            display: "inline-block",
            fontSize: 16,
            fontFamily: "Times New Roman",
            borderRadius: 30,
            border: "1px solid #b71c1c",
            padding: "4px 10px",
          }}>
          !
        </div>
        {message}
      </div>
    </>
  );
}
