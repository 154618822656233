import { useCallback, useMemo } from "react";
import { redirectToOAuthAuthorize } from "../oauth";
import { useSessionRedirect } from "./useSessionRedirect";

export interface Navigator {
  toValidate2FA(): void;
  toSetup2FARequiredEmailCheckpoint(): void;
  toPasswordReset(): void;
  toSecurityCheckup(): void;
  toDashboard(): void;
  toOAuthAuthorize(): void;
  toUnifiedSignup(): void;
}

export const useExternalNavigate = () => {
  const makoHost = process.env.REACT_APP_MAKO_HOST || "https://app.sendgrid.com";
  const signupHost = process.env.REACT_APP_SIGNUP_HOST || "https://signup.sendgrid.com";
  const { addSessionRedirect, encodeSessionRedirect } = useSessionRedirect();

  const externalNavigate = useCallback((url: string) => {
    window.location.replace(url);
  }, []);

  return useMemo(() => {
    return {
      toValidate2FA: () => {
        let route = `${makoHost}/validate_2fa`;
        route = encodeSessionRedirect(route);
        externalNavigate(route);
      },

      toValidate2FAForUnifiedAccountLinking: () => {
        const accountLinkingPromptUrl = `${window.location.protocol}//${window.location.host}/unified_account_linking/prompt`;
        let route = `${makoHost}/validate_2fa?redirect_to=${accountLinkingPromptUrl}`;
        route = encodeSessionRedirect(route);
        externalNavigate(route);
      },

      toSetup2FARequiredEmailCheckpoint: () => {
        let route = `${makoHost}/2fa_email_checkpoint`;
        route = encodeSessionRedirect(route);
        externalNavigate(route);
      },

      toPasswordReset: () => {
        let route = `${makoHost}/password_reset_required`;
        route = encodeSessionRedirect(route);
        externalNavigate(route);
      },

      toSecurityCheckup: () => {
        let route = `${makoHost}/security_checkup`;
        route = encodeSessionRedirect(route);
        externalNavigate(route);
      },

      toDashboard: () => {
        let route = `${makoHost}/`;
        route = addSessionRedirect(route);
        externalNavigate(route);
      },

      toOAuthAuthorize: () => {
        let route = redirectToOAuthAuthorize(addSessionRedirect(""));
        externalNavigate(route);
      },

      toUnifiedSignup: () => {
        let route = `${signupHost}/unified_login/signup`;
        route = encodeSessionRedirect(route);
        externalNavigate(route);
      },

      toGuide: () => {
        let route = `${makoHost}/guide`;
        route = encodeSessionRedirect(route);
        externalNavigate(route);
      },

      toAccountDetails: () => {
        let route = `${makoHost}/account/details`;
        route = encodeSessionRedirect(route);
        externalNavigate(route);
      },
    };
  }, [makoHost, encodeSessionRedirect, addSessionRedirect, signupHost, externalNavigate]);
};
