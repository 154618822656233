import { useCallback, useMemo } from "react";
import { useCookies } from "react-cookie";
import { SESSION_COOKIE_NAME } from "./constants";

export const seedRandomSessionIdentifier = () => {
  // Generates a fairly long random identifier for the session
  // This cookie doesn't matter for auth and is used only to identify session in 3rd party integs
  return `${(Math.random() * 1e16).toString(36)}-${(Math.random() * 1e16).toString(
    36
  )}-${(Math.random() * 1e16).toString(36)}`;
};

export const useSessionIdentifier = () => {
  const [cookies, setCookie, removeCookie] = useCookies([SESSION_COOKIE_NAME]);
  const sessionIdentifier = useMemo(() => {
    return cookies.session_identifier;
  }, [cookies]);
  const setSessionIdentifier = useCallback(
    (value: string) => {
      setCookie(SESSION_COOKIE_NAME, value);
    },
    [setCookie]
  );
  const removeSessionIdentifier = useCallback(() => {
    removeCookie(SESSION_COOKIE_NAME);
  }, [removeCookie]);
  return useMemo(
    () => [sessionIdentifier, setSessionIdentifier, removeSessionIdentifier],
    [sessionIdentifier, setSessionIdentifier, removeSessionIdentifier]
  );
};
