import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  HelpText,
  Input,
  Label,
  Paragraph,
  Text,
} from "@twilio-paste/core";
import { CopyIcon } from "@twilio-paste/icons/esm/CopyIcon";
import { LinkIcon } from "@twilio-paste/icons/esm/LinkIcon";

import { useCallback, useEffect, useState } from "react";
import { NavbarContainer } from "../../NavbarContainer";
import { useSessionUsername, useUnifiedAuth } from "../../unified-auth";
import { UnifiedProvisionError } from "../../unified-auth/constants";
import { AccountLinkErrorModal } from "./components/AccountLinkErrorModal";
import { LogoAvatar } from "./components/LogoAvatar";

export const ConfirmUnifiedAccountLink = () => {
  const [sessionUsername] = useSessionUsername();
  const [oldUsername] = useState(sessionUsername);
  const {
    confirmUnifiedAccountLinking,
    navigateToGuide,
    skipUnifiedAccountLinking,
    redirectToUnifiedAuthWithLinking,
  } = useUnifiedAuth();
  const { user: unifiedUserInfo, isLoading, logout: logoutWithTwilioAuth0 } = useAuth0();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorType, setErrorType] = useState<UnifiedProvisionError>(
    UnifiedProvisionError.GENERAL_ERROR
  );

  useEffect(() => {
    if (!isLoading && !unifiedUserInfo) {
      setErrorType(UnifiedProvisionError.AUTH0_ERROR);
      setShowErrorModal(true);
    }
  }, [isLoading, unifiedUserInfo]);

  const onConfirmUnifiedAccountLinking = useCallback(async () => {
    const result = await confirmUnifiedAccountLinking();
    if (!result.success) {
      if (result.error === UnifiedProvisionError.USERNAME_IN_USE) {
        setErrorType(UnifiedProvisionError.USERNAME_IN_USE);
      } else if (result.error === UnifiedProvisionError.AUTH0_EMAIL_MISMATCH) {
        setErrorType(UnifiedProvisionError.AUTH0_EMAIL_MISMATCH);
      } else {
        setErrorType(UnifiedProvisionError.GENERAL_ERROR);
      }
      setShowErrorModal(true);
      return;
    }
    navigateToGuide();
  }, [confirmUnifiedAccountLinking, navigateToGuide]);

  const onRetryUnifiedAccountLinkingFlow = useCallback(() => {
    logoutWithTwilioAuth0({
      logoutParams: {
        returnTo: `${window.location.origin}/unified_account_linking/prompt`,
      },
    });
  }, [logoutWithTwilioAuth0]);

  const onErrorModalConfirm = useCallback(() => {
    setShowErrorModal(false);
    if (errorType === UnifiedProvisionError.AUTH0_ERROR) {
      redirectToUnifiedAuthWithLinking("login");
    } else {
      skipUnifiedAccountLinking();
    }
  }, [errorType, redirectToUnifiedAuthWithLinking, skipUnifiedAccountLinking]);

  const copyNewUsername = useCallback(() => {
    if (unifiedUserInfo?.email) {
      navigator.clipboard?.writeText(unifiedUserInfo?.email);
    }
  }, [unifiedUserInfo?.email]);

  return (
    <>
      <NavbarContainer showProductSwitcher={false} showUserMenu={false} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        alignItems={"center"}
        paddingTop={"space200"}
        marginBottom={"space200"}
        height={"100vh"}>
        <Box paddingTop={"space200"} maxWidth={"size60"}>
          <Card padding={"space90"}>
            <Heading as={"h1"} variant={"heading30"} marginBottom={"space0"}>
              Your Twilio credentials will now be used as your SendGrid login
            </Heading>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              columnGap={"space40"}
              paddingTop={"space90"}>
              <LogoAvatar logo="sendgrid" />
              <LinkIcon decorative color={"colorTextWeak"} />
              <LogoAvatar logo={"twilio"} />
            </Box>
            <Box paddingY={"space60"}>
              <Paragraph marginBottom="space0">
                You will now sign in to SendGrid with your Twilio login credentials, where your user
                profile and 2FA settings will be managed through Twilio. You will still be able to
                login to SendGrid using https://login.sendgrid.com/.
              </Paragraph>
            </Box>
            <Box>
              <Label htmlFor="new_sendgrid_username">New SendGrid username</Label>
              <Input
                id="new_sendgrid_username"
                name="new_sendgrid_username"
                aria-describedby="new_sendgrid_username"
                aria-readonly="true"
                type="text"
                readOnly
                value={unifiedUserInfo?.email}
                insertAfter={
                  <Text
                    as={"span"}
                    color={"colorTextLink"}
                    onClick={copyNewUsername}
                    cursor="pointer"
                    data-testid={"clipboard-icon"}>
                    <CopyIcon decorative />
                  </Text>
                }
              />
              <HelpText id="new_sendgrid_username">
                Your SendGrid username {oldUsername} has now been updated to this Twilio email
              </HelpText>
            </Box>
            <Box paddingTop={"space60"}>
              <Paragraph marginBottom="space0">
                Your account friendly name (only visible in the console) will be{" "}
                <strong>&ldquo;My default SendGrid account&rdquo;</strong>
              </Paragraph>
            </Box>
            <Flex hAlignContent={"right"} paddingTop={"space90"}>
              <Button
                variant="primary"
                aria-disabled={false}
                onClick={onConfirmUnifiedAccountLinking}
                data-testid={"confirm-link-button"}>
                I understand
              </Button>
            </Flex>
          </Card>
        </Box>
        <AccountLinkErrorModal
          showModal={showErrorModal}
          type={errorType}
          onConfirm={onErrorModalConfirm}
          onRetry={onRetryUnifiedAccountLinkingFlow}
        />
      </Box>
      <Box height={"174px"} />
    </>
  );
};
