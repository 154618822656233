import { CookieSetOptions } from "universal-cookie";

export const cookieSetOptions = () => {
  let opts: CookieSetOptions = {
    secure: false,
    path: "/",
  };
  if (window.location.hostname !== "localhost") {
    const defaultCookieDomain = process.env.REACT_APP_MAKO_COOKIE_DOMAIN || ".sendgrid.com";
    opts.domain = defaultCookieDomain;
    opts.sameSite = "lax";
  }
  if (process.env.NODE_ENV === "production") {
    opts.secure = true;
  }
  return opts;
};
