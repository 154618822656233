import { useCallback, useState } from "react";

function getLocalStorageOrDefault<T>(key: string, defaultValue: T): T {
  try {
    const stored = localStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return JSON.parse(stored);
  } catch (e) {
    console.warn(`failed to read or parse ${key} local storage item`, e);
    return defaultValue;
  }
}

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void, () => void] => {
  const [value, setValue] = useState(getLocalStorageOrDefault<T>(key, defaultValue));

  const setLocalStore = useCallback(
    (value: T) => {
      if (value === null) {
        throw new Error("Call removeLocalStorage if you want to remove this value");
      }
      try {
        localStorage.setItem(key, JSON.stringify(value));
        setValue(value);
      } catch (e) {
        console.warn(`failed to set or remove ${key} local storage item`, e);
      }
    },
    [key, setValue]
  );

  const removeLocalStore = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setValue(defaultValue);
    } catch (e) {
      console.warn(`failed to remove ${key} local storage item`, e);
    }
  }, [key, setValue, defaultValue]);

  return [value, setLocalStore, removeLocalStore];
};
