/**
 * Store the interface to the analytics for tracking the user.
 */

export interface Identity {
  authToken?: string;
  traits?: object;
}

/**
 * Track Event is an object we pass to the tracking analytics to track an event.
 */
export interface TrackEvent {
  event: string;
  properties?: TrackEventProperties;
  callback?: () => void;
}

export interface TrackEventProperties {
  page?: string;
  location?: string;
  section?: string;
  subsection?: string;
  eventType?: string;
  elementType?: string;
  description?: string;
  [key: string]: string | number | boolean | undefined;
}

/**
 * The analytics component for tracking a user.
 */
export interface Analytics {
  identify: (identity: Identity) => Promise<void>;
  track: (data: TrackEvent) => void;
}

export const NoopAnalytics: Analytics = {
  identify: (identity: Identity): Promise<void> => {
    return Promise.resolve();
  },
  track: (data: TrackEvent): Promise<void> => {
    return Promise.resolve();
  },
};
