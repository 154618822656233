import React from "react";
import { ExternalNavigateOptions, To } from "./hooks";

export interface ExternalNavigator {
  push(to: To, opts?: ExternalNavigateOptions): void;
  replace(to: To, opts?: ExternalNavigateOptions): void;
  createHref(to: To, opts?: ExternalNavigateOptions): string;
}

export interface ExternalStorage {
  set(key: string, entry: string): void;
  get(key: string): string | undefined;
  remove(key: string): void;
}

export interface ExternalNavigationContextObject {
  basename: string;
  externalNavigator: ExternalNavigator;
  store: ExternalStorage;
}

export const ExternalNavigateContext = React.createContext<ExternalNavigationContextObject>(null!);

export interface ExternalLocationContextObject {
  location: Location;
}

export const ExternalLocationContext = React.createContext<ExternalLocationContextObject>(null!);
